var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"name":_vm.$t('bank_accounts.bank'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('bank_accounts.bank')) + " *"),"label-cols-lg":"3","label-cols-sm":"4","label-for":"bank_code"}},[_c('b-form-select',{attrs:{"id":"bank_code","state":errors[0] ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(("-- " + (_vm.$t('fields.click_select')) + " --"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.form.bankCode),callback:function ($$v) {_vm.$set(_vm.form, "bankCode", $$v)},expression:"form.bankCode"}},[_vm._l((_vm.thBanks),function(bank){return _c('b-form-select-option',{key:bank.bank_code,attrs:{"value":bank.bank_code}},[_vm._v(" "+_vm._s(bank.bank_name_th)+" ")])}),_c('b-form-select-option',{attrs:{"value":"PAYMENT_GATEWAY"}},[_vm._v(" Payment Gateway ")])],2)],1)]}}])}),(_vm.form.bankCode !== 'PAYMENT_GATEWAY')?[_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            min: 10,
            regex: /^[0-9]+$/,
          },"name":"account_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('bank_accounts.account_no')) + " *"),"label-cols-lg":"3","label-cols-sm":"4","label-for":"bank_number"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],attrs:{"id":"bank_number","aria-invalid":false,"state":errors[0] ? false : null,"aria-describedby":"input-live-feedback"},model:{value:(_vm.form.bankAccountNumber),callback:function ($$v) {_vm.$set(_vm.form, "bankAccountNumber", $$v)},expression:"form.bankAccountNumber"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,55614882)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('bank_accounts.account_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('bank_accounts.account_name')) + " " + ('*')),"label-cols-lg":"3","label-cols-sm":"4","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null},model:{value:(_vm.form.bankAccountName),callback:function ($$v) {_vm.$set(_vm.form, "bankAccountName", $$v)},expression:"form.bankAccountName"}})],1)]}}],null,false,2980866662)})]:_vm._e(),(_vm.form.bankCode != 'TRUE')?_c('b-form-group',{attrs:{"label":("" + (_vm.$t('bank_accounts.allow_bank_code_list'))),"label-cols-lg":"3","label-cols-sm":"4","label-for":"allow_bank_code_list"}},[_c('multiselect',{attrs:{"clear-on-select":false,"close-on-select":false,"multiple":true,"options":_vm.thBanks,"preselect-first":false,"preserve-search":true,"label":"bank_name_th","placeholder":"Pick some","track-by":"bank_code"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var values = ref.values;
          var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}],null,false,4043715844),model:{value:(_vm.tmp),callback:function ($$v) {_vm.tmp=$$v},expression:"tmp"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }