<template>
  <div>
    <ValidationProvider
        v-slot="{ errors }"
        :name="$t('bank_accounts.bank')"
        rules="required"
    >
      <b-form-group
          :label="`${$t('bank_accounts.bank')} *`"
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="bank_code"
      >
        <b-form-select
            id="bank_code"
            v-model="form.bankCode"
            :state="errors[0] ? false : null"
        >
          <template #first>
            <b-form-select-option disabled value="">
              {{ `-- ${$t('fields.click_select')} --` }}
            </b-form-select-option>
          </template>
          <b-form-select-option
              v-for="bank in thBanks"
              :key="bank.bank_code"
              :value="bank.bank_code"
          >
            {{ bank.bank_name_th }}
          </b-form-select-option>
          <b-form-select-option value="PAYMENT_GATEWAY">
            Payment Gateway
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </ValidationProvider>

    <template v-if="form.bankCode !== 'PAYMENT_GATEWAY'">
      <ValidationProvider
          v-slot="{ errors }"
          :rules="{
              required: true,
              min: 10,
              regex: /^[0-9]+$/,
            }"
          name="account_no"
      >
        <b-form-group
            :label="`${$t('bank_accounts.account_no')} *`"
            label-cols-lg="3"
            label-cols-sm="4"
            label-for="bank_number"
        >
          <b-form-input
              id="bank_number"
              v-model="form.bankAccountNumber"
              v-mask="'############'"
              :aria-invalid="false"
              :state="errors[0] ? false : null"
              aria-describedby="input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          v-slot="{ errors }"
          :name="$t('bank_accounts.account_name')"
          rules="required"
      >
        <b-form-group
            :label="`${$t('bank_accounts.account_name')} ${'*'}`"
            label-cols-lg="3"
            label-cols-sm="4"
            label-for="name"
        >
          <b-form-input
              id="name"
              v-model="form.bankAccountName"
              :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
    </template>
    <b-form-group
        v-if="form.bankCode != 'TRUE'"
        :label="`${$t('bank_accounts.allow_bank_code_list')}`"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="allow_bank_code_list"
    >
      <multiselect 
        v-model="tmp" 
        :clear-on-select="false"
        :close-on-select="false"
        :multiple="true"
        :options="thBanks"
        :preselect-first="false"
        :preserve-search="true"
        label="bank_name_th" 
        placeholder="Pick some"
        track-by="bank_code"
      >
        <template slot="selection" slot-scope="{ values, isOpen }"><span v-if="values.length &amp;&amp; !isOpen" class="multiselect__single">{{ values.length }} options selected</span></template>
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
import {banks} from '@utils/data-mock'
import Vue from 'vue';
import {BankCodeEnum} from '@src/constants/bank';

export default Vue.extend({
  name: 'BankAccountForm',
  props: {
    bankAccountForm: {
      type: Object,
      default: () => ({
        bankCode: '',
        bankAccountNumber: '',
        bankAccountName: '',
        allowBankCodeList: [],
      })
    }
  },
  data() {
    return {
      form: {},
      tmp: []
    }
  },
  computed: {
    thBanks() {
      return banks.filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
    }
  },
  watch: {
    tmp: {
      handler(val) {
        this.form.allowBankCodeList = []
        if (val.length > 0) {
          for(let i = 0 ; i < val.length ; i++) {
            this.form.allowBankCodeList.push(val[i].bank_code)
          }
        }
      }
    },
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      bankCode: this.bankAccountForm.bankCode,
      bankAccountNumber: this.bankAccountForm.bankAccountNumber,
      bankAccountName: this.bankAccountForm.bankAccountName,
      allowBankCodeList: this.bankAccountForm.allowBankCodeList || [],
    }
    if (this.form.allowBankCodeList && this.form.allowBankCodeList.length > 0 ) {
      this.form.allowBankCodeList.map((bankCode) => {
        const bankExist = banks
          .filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
          .find((bank) => {
            return bank.bank_code === bankCode
          })
        this.tmp.push(bankExist)
      })
    } else {
      this.tmp = []
    }
  }
})
</script>

<style scoped>

</style>